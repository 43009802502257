@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap");

@font-face {
  font-family: "Magical Story Outline";
  src: url("/public/assets/fonts/MagicalStory.woff") format("woff2"),
    url("/public/assets/fonts/MagicalStoryOutline.woff") format("woff"),
    url("/public/assets/fonts/MagicalStoryShadow.woff") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.MainHead {
  text-align: center;
  -webkit-text-stroke-width: 5px;
  -webkit-text-stroke-color: #5f84bb;
  font-family: "Magical Story Outline";
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: 0.375px;
}

.HeaderStroke {
  -webkit-text-stroke-width: 5px;
  -webkit-text-stroke-color: #417e9f;
}

.secondarystroke {
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #8bb4f0;
}

.AboutHead {
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #8bb4f0;
}

.cartHeading {
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #fff;
}

.shppingshadow {
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #417e9f;
}

.socialhadow {
  box-shadow: 7.814px 10.744px 3.907px 0px #00476f;
}

.Socialshadow2 {
  box-shadow: 8.574px 11.79px 4.287px 0px #00476f;
}

@media screen and (min-width: 600px) and (max-width: 1000px) {
  .MainHead {
    -webkit-text-stroke-width: 3px;
  }
  .HeaderStroke {
    -webkit-text-stroke-width: 2px;
  }
}

@media screen and (min-width: 0px) and (max-width: 600px) {
  .MainHead {
    -webkit-text-stroke-width: 1.5px;
  }
  .HeaderStroke {
    -webkit-text-stroke-width: 1.5px;
  }
  .secondarystroke {
    -webkit-text-stroke-width: 1px;
  }
  .cartHeading {
    -webkit-text-stroke-width: 1px;
  }
  .shppingshadow {
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #417e9f;
  }
  .image_bubble {
    width: 140px;
    margin-top: -56px;
    margin-left: 10px;
  }
}


.white_border_center {
  min-width: 40% !important;
  max-width: 90%;
  color: #202020;
}
.stripe_button {
  background: rgb(13, 180, 240);
  padding: 10px;
  width: 100%;
  border-radius: 10px;
  margin: 10px 0;
  height: 60px;
  color: #fff;
  font-weight: bold;
}
.stripe_button:hover {
  background: #202020;
  padding: 10px;
  width: 100%;
  border-radius: 10px;
  margin: 10px 0;
  height: 60px;
  color: #fff;
  font-weight: bold;
}
.price_table {
  border-bottom: 1px dashed #f0f0f0;
  padding-bottom: 10px;
}

.billing_form {
  border: 1px solid #ccc;
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 12px;
}

.white_max_height {
  max-height: calc(100vh - 80px);
  overflow: auto;
}